.app {
	position: absolute;
	overflow: hidden;
	width: 100vw;
}

.player {
	z-index: 1;
}

.information {
	justify-content: flex-end;
	background-color: white;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px;
	display: flex;
	width: 100vw;
	z-index: 2;
}

.tag {
	padding: 0 0 15px 0;
}

.download {
	background-color: red;
	margin: auto;
}

.moreInfo {
	margin: 10px 0;
}