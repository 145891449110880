.app {
	margin: 20px auto;
	display: flex;
	max-width: 800px;
	flex-direction: row;
}

.playerContainer {
	background-color: gray;
}

.player {
	margin: auto;
}

.information {
	width: calc(100% - 55vh);
	padding: 15px;
	box-sizing: border-box;
	background-color: white;
}

.content {
	margin: 20px 0;
}

.moreInfo {
	margin: 10px 0;
}